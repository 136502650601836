<template>
  <div class="content-container">
    <v-container fluid>
      <v-row align="center"
             justify="center">
        <v-col class="text-justify pr-lg-0" cols="12">
          <div v-intersect="{
                                    handler: onIntersect,
                                    options: {
                                      threshold: [0, 0.5, 1.0]
                                    }
                                  }" ref="base" class="pa-2 overflow-hidden">
            <div class="text-center">
              <h2 class="display-1 font-weight-thin black--text mb-10 underlined-header">
                                <span class="text">
                                    Contact Us
                                </span>
                <span class="line-wrapper">
                                    <span class="line"></span>
                                </span>
              </h2>
            </div>

            <p class="text-base text-center">
              Do you have any questions that you'd like to ask, maybe a suggestion or idea you would want to share?<br/><br/>
              <strong>Don't hesitate to reach out, we will be happy to reach back!</strong>
            </p>

            <contact-form context="general"></contact-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ContactForm from "@/components/ContactForm";

export default {
  name: 'HomeContactUs',
  components: {ContactForm},
  props: {},

  data: () => ({}),

  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].intersectionRatio >= 0.5;
      if (!isIntersecting) {
        this.$refs.base.classList.remove('underlined-header-active');
      } else {
        if (this.$refs.base.classList.contains('underlined-header-active') === false) {
          this.$refs.base.classList.add('underlined-header-active');
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
div.text-base {
  max-width: 750px;
  margin: auto;
}
</style>