<template>
  <div class="home">
    <intro-photo ref="home"></intro-photo>
    <home-about-us ref="about-us"></home-about-us>
    <home-historic-pictures ref="historic-pictures"></home-historic-pictures>
    <home-contact-us ref="contact-us"></home-contact-us>
  </div>
</template>

<script>

import IntroPhoto from "@/views/IntroPhoto";
import HomeAboutUs from "@/views/HomeAboutUs";
import HomeHistoricPictures from "@/views/HomeHistoricPictures";
import HomeContactUs from "@/views/HomeContactUs";
import goTo from 'vuetify/es5/services/goto'

export default {
  name: 'Home',
  components: {
    HomeContactUs,
    HomeHistoricPictures,
    HomeAboutUs,
    IntroPhoto
  },

  data: () => ({
    ignoreNext: false,
  }),

  watch: {
    $route(to) {
      this.goToSection(to);
    }
  },

  methods: {
    goToSection(route) {
      if (this.ignoreNext) {
        this.ignoreNext = false;
        return;
      }
      switch (route.path) {
        case '/about-us':
          goTo(this.$refs['about-us']);
          break;
        case '/historic-pictures':
          goTo(this.$refs['historic-pictures']);
          break;
        case '/contact-us':
          goTo(this.$refs['contact-us']);
          break;
      }
    },

  },

  mounted() {
    this.goToSection(this.$route);
    this.$emit('setPermanentMenu', false);
  }
}
</script>

<style lang="scss">

</style>