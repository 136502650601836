import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Distribution from "@/views/Distribution";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: Home
  },
  {
    path: '/historic-pictures',
    name: 'Historic Pictures',
    component: Home
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: Home
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: Distribution
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
