const menu = [
  {
    title: "About Us",
    link: "/about-us"
  },
  {
    title: "Historic Pictures",
    link: "/historic-pictures"
  },
  {
    title: "Distribution",
    link: "/distribution"
  },
  {
    title: "Contact Us",
    link: "/contact-us"
  },
];

export default menu