<template>
  <div class="content-container">
    <v-container fluid>
      <v-row align="center"
             justify="center">
        <v-col class="text-justify pr-lg-0" cols="12">
          <v-card class="ml-auto pr-lg-6 mr-lg-n2"
                  max-width="800">
            <v-card-text>
              <div v-intersect="{
                                    handler: onIntersect,
                                    options: {
                                      threshold: [0, 0.3, 1.0]
                                    }
                                  }" ref="base" class="pa-2 overflow-hidden">
                <div class="text-center text-lg-left">
                  <h2 class="display-1 font-weight-thin black--text mb-10 underlined-header">
                                        <span class="text">
                                            Historic Pictures
                                        </span>
                    <span class="line-wrapper">
                                            <span class="line"></span>
                                        </span>
                  </h2>
                </div>

                <div class="text-base black--text">
                  <p>
                    Since the invention and popularization of photography in the 1800s, photos have
                    become an invaluable way for us to open the door to the past, capturing specific
                    moments in time and preserving them for later generations.
                  </p>
                  <p>
                    Nowadays, with the popularity of various photo and video sharing apps, nearly
                    every moment of our lives
                    is documented and saved for us to return to them later.
                  </p>
                  <p>
                    <strong>This is what makes historical pictures special.</strong><br/>
                    There are comparatively few reminders of what our world looked like before the
                    advent of digital
                    photography. Historical pictures can help us to preserve the memories of people
                    who
                    have left us since, and remind us of places that have changed.
                  </p>
                  <p>
                    Historical pictures are a valuable research tool. <br/>
                    Architectural photography can be of great use in the process of building
                    restoration. <br/>
                    Biographers can glean new information about their subjects from a single photo —
                    or perhaps find more proof to support what they already knew. <br/>
                    Even someone simply looking into their family history can discover new surprises
                    or memories to be cherished&nbsp;—&nbsp;all with the help of an picture.
                  </p>
                  <p>
                    Looking at a Historical Picture, we are invited to pause for a moment, to
                    contemplate the passage of time. In a way, even if it's only just for a brief
                    while, it lets us <strong>travel back in time to a moment that would otherwise
                    be lost forever</strong>.
                  </p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'HomeHistoricPictures',
  props: {},

  data: () => ({}),

  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].intersectionRatio >= 0.3;
      if (!isIntersecting) {
        this.$refs.base.classList.remove('underlined-header-active');
      } else {
        if (this.$refs.base.classList.contains('underlined-header-active') === false) {
          this.$refs.base.classList.add('underlined-header-active');
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
div.content-container {
  margin: -12px -12px -12px -12px;
  background-color: $hp-secondary;
  background-image: url("./../assets/images/historic_pictures.jpg");
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;


  div.text-base {
    max-width: 750px;
    margin: auto;
  }
}
</style>