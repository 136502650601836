<template>
  <v-app v-resize="onResize" v-bind:class="{'mobile': this.mobile, 'permanent-menu': this.permanentMenu}">
    <div class="bg-scroll-color-fix"></div>
    <v-navigation-drawer app
                         right
                         temporary
                         dark
                         color="#3C2C23"
                         height="100%"
                         :mobile-break-point="mobileBreakPoint"
                         :width="300"
                         v-model="menuOpen"
                         v-if="mobile">
      <v-list>
        <v-list-item
          v-for="item in menu"
          :key="item.title"
          :to="item.link"
          link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2 text-center">
          <div class="logo top-menu ma-auto"></div>
          <div class="copyright mt-1">&copy; HistoricPix. All Rights Reserved.</div>
        </div>
      </template>
    </v-navigation-drawer>

    <TopNavigation :mobile="mobile" @openNavigationDrawer="menuOpen=true"></TopNavigation>

    <v-content>
      <v-container fluid class="main-content-wrapper">
        <router-view v-on:setPermanentMenu="permanentMenu=$event"></router-view>
      </v-container>
    </v-content>

    <Footer></Footer>
  </v-app>
</template>

<script>
import menu from '@/modules/navigation';
import TopNavigation from "@/components/TopNavigation";
import Footer from "@/components/Footer";

export default {
  name: 'App',

  watch: {
    '$route': {
      handler(v) {
        document.title = 'Historic Pix' + ((v.name || '').length > 0 ? ' - ' + v.name : '');
      },
      immediate: true,
    }
  },

  components: {
    Footer,
    TopNavigation,
  },

  mounted() {
    this.onResize()
  },

  data: () => ({
    menu: menu,
    permanentMenu: false,
    mobileBreakPoint: 1264,
    mobile: false,
    menuOpen: false,
  }),

  methods: {
    onResize() {
      this.mobile = window.innerWidth <= this.mobileBreakPoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  letter-spacing: .03em;
  background-color: #ffffff !important;
  color: #000000;
  cursor: default;
}
</style>
