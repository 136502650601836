<template>
  <div class="menu"
       ref="menu"
       v-bind:class="{'mobile': mobile}"
       v-scroll="onScroll">
    <v-container :fluid="mobile">
      <v-row dense align="center" justify="center" v-if="!mobile">
        <v-col cols="2">
          <div class="logo top-menu"></div>
        </v-col>
        <v-col cols="2"
               class="top-menu-base text-center"
               v-for="item in menu"
               :key="item.title">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </v-col>
        <v-col></v-col>
      </v-row>

      <v-row dense v-if="mobile">
        <v-col cols="10" class="mobile-col">
          <div class="logo main-menu mobile"></div>
        </v-col>
        <v-col cols="2" class="text-right mobile-col">
          <div class="hamburger" v-ripple @click="$emit('openNavigationDrawer')">
            <v-icon color="#FFFFFF">mdi-menu</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import menu from '@/modules/navigation';

export default {
  name: 'TopNavigation',

  props: {
    mobile: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    menu: menu,
  }),

  methods: {
    onScroll() {
      let coef = window.innerHeight / 70;
      if (window.scrollY < (window.innerHeight / coef)) {
        this.$refs.menu.classList.remove('offset');
      } else {
        if (this.$refs.menu.classList.contains('offset') === false) {
          this.$refs.menu.classList.add('offset');
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.permanent-menu {
  div.menu {
    background-color: $hp-secondary !important;

    .mobile-col {
      > div.logo.main-menu.mobile {
        opacity: 1;
      }
    }
  }
}

div.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: $hp-menu-height;
  background-color: transparent;
  transition: background-color 0.2s ease-out;

  &.offset {
    background-color: $hp-secondary;

    .mobile-col {
      > div.logo.main-menu.mobile {
        opacity: 1;
      }
    }
  }

  &.mobile {
    height: $hp-menu-height-mobile;
  }

  .top-menu-base {
    > a {
      color: white;
      text-decoration: none;
      font-size: 1.1rem;
      font-weight: normal;

      &:hover,
      &:focus {
        color: $hp-primary;
      }
    }
  }

  .mobile-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    > div.logo.main-menu.mobile {
      opacity: 0;
      margin-left: 5px;
      transition: opacity 0.2s ease-out;
    }

    > .hamburger {
      margin: 0 0 0 auto;
      width: 42px;
      height: 42px;
      padding: 9px;
    }
  }
}
</style>