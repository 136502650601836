<template>
  <div class="intro-photo">
    <v-container>
      <v-row>
        <v-col class="text-center text-lg-left white--text" cols="12">
          <div class="logo top-menu mt-8 mt-sm-0 mb-12 mb-sm-4 d-lg-none"></div>
          <div class="menu-spacer d-none d-lg-block"></div>
          <h1 class="display-2 font-weight-thin mb-10 mt-10 d-none d-lg-block">
            Preserving Moments<br/>for you to enjoy
          </h1>
          <h1 class="display-1 font-weight-thin mb-10 d-lg-none">
            Preserving Moments<br/>for you to enjoy
          </h1>
          <router-link to="/distribution" class="cta">
            Find Your Picture
            <v-icon>mdi-arrow-right-circle-outline</v-icon>
          </router-link>
          <div class="scroller-spacer d-none"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'IntroPhoto',
  components: {}
}
</script>

<style lang="scss" scoped>
div.intro-photo {
  position: relative;
  width: auto;
  min-height: 100%;
  min-height: 100vh;
  margin: -12px -12px 0 -12px;
  background-color: $hp-secondary;
  background-image: url("./../assets/images/home.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  > div.fill-height {
    max-height: 80%;
  }

  h1.display-2 {
    line-height: 3.6rem;
  }

  div.menu-spacer {
    height: $hp-menu-height * 1.5;
  }

  div.scroller-spacer {
    height: 80px;
  }

  > div.container {
    padding-top: $hp-menu-height-mobile / 2;
  }

  a.cta i {
    color: white !important;
    position: relative;
    top: -2px;
    margin-left: 5px;
    text-decoration: none;
    transition: color 0.2s ease-out;
  }

  a.cta {
    transition: color 0.2s ease-out;
    color: white !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.5rem;
  }

  a.cta:hover, a.cta:hover i {
    color: $hp-accent !important;
  }
}
</style>
