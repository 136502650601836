<template>
  <div class="content-container">
    <v-container>
      <v-row align="center"
             justify="center">
        <v-col class="text-center" cols="12">
          <div v-intersect="{
                            handler: onIntersect,
                            options: {
                              threshold: [0, 0.5, 1.0]
                            }
                          }" ref="base" class="pa-2">
            <h2 class="display-1 font-weight-thin mb-10 underlined-header">
                            <span class="text">
                                Distribution
                            </span>
              <span class="line-wrapper">
                                <span class="line"></span>
                            </span>
            </h2>

            <div class="text-base text-justify text-lg-center">
              <p>
                Our main goal is finding the perfect picture for each buyer, right now that is mostly
                done by working with each buyer individually&nbsp;—&nbsp;don't hesitate to contact us
                with topics,
                celebrities, events or any other details about a picture you are interested in, we will
                get in touch with you about our options.
              </p>
              <p>
                We also occasionally participate in various photo auctions all over the world, working
                in different markets, ensuring that each picture is offered to the most suitable buyers
                and ends up in the hands of someone who truly understands its value.
              </p>
              <br/><br/>
              <p class="text-base text-justify text-lg-center">
                <strong>Use the form below to reach out with your inquiry about buying pictures, we will be
                  in touch with our offer!</strong>
              </p>
            </div>

            <contact-form context="distribution"></contact-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import goTo from 'vuetify/es5/services/goto'

export default {
  name: 'HomeAboutUs',
  components: {ContactForm},
  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].intersectionRatio >= 0.5;
      if (!isIntersecting) {
        this.$refs.base.classList.remove('underlined-header-active');
      } else {
        if (this.$refs.base.classList.contains('underlined-header-active') === false) {
          this.$refs.base.classList.add('underlined-header-active');
        }
      }
    },
  },

  mounted() {
    this.$emit('setPermanentMenu', true);
    goTo(0);
  },
}
</script>

<style lang="scss">
div.text-base {
  max-width: 750px;
  margin: auto;
}
</style>
