<template>
  <div class="pa-2 footer">
    <v-container class="pb-1">
      <v-row>
        <v-col class="pb-0">
          <div class="copyright mt-1">&copy; HistoricPix. All Rights Reserved.</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  props: {},

  data: () => ({}),

  methods: {}
}
</script>

<style lang="scss" scoped>
div.footer {
  background-color: #000000;
}
</style>