import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";
import router from './modules/router'

require('./scss/logos.scss')
require('./scss/common.scss')

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: {id: "UA-168670449-1"}
}, router)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
