<template>
  <div class="content-container">
    <v-container>
      <v-row align="center"
             justify="center">
        <v-col class="text-center" cols="12">
          <div v-intersect="{
                            handler: onIntersect,
                            options: {
                              threshold: [0, 0.5, 1.0]
                            }
                          }" ref="base" class="pa-2">
            <h2 class="display-1 font-weight-thin mb-10 underlined-header">
                            <span class="text">
                                About Us
                            </span>
              <span class="line-wrapper">
                                <span class="line"></span>
                            </span>
            </h2>

            <div class="text-base text-justify text-lg-center">
              <p>
                It is often said that a picture is worth a thousand words. <br/>
                We at Historic Pix couldn't agree more&nbsp;—&nbsp;<strong>a single image can contain and convey
                an entire story</strong>.
              </p>
              <p>
                And we believe that these stories are worth preserving and sharing. <br/>
                Each photo exists as a memento of a particular time and place, and through the medium of
                image we can
                return there.
              </p>
              <p>
                We help stories told through photos to travel the world and reach the perfect viewer.
              </p>
              <p>
                In collaboration with major publishing companies, archives, and private collectors we distribute unique
                historic pictures at auctions all over the world.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HomeAboutUs',

  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].intersectionRatio >= 0.5;
      if (!isIntersecting) {
        this.$refs.base.classList.remove('underlined-header-active');
      } else {
        if (this.$refs.base.classList.contains('underlined-header-active') === false) {
          this.$refs.base.classList.add('underlined-header-active');
        }
      }
    },
  }
}
</script>

<style lang="scss">
div.text-base {
  max-width: 750px;
  margin: auto;
}
</style>
